














import List from "@/components/List.vue";
import Vue from "vue";
import ComponentsCardContainer from "@/components/ComponentsCardContainer.vue";
import { MachineView } from "@/types/MachineView";
import { getAllMachines } from "@/api/MachinesAPI";
import { getDateFromIot } from "@/utils/DateHandlers";

export default Vue.extend({
  name: "MachineList",
  components: { ComponentsCardContainer, List },
  data: () => ({
    machines: [] as MachineView[],
    loading: true,
    error: "",
    headers: [
      {
        text: "Codice SGD",
        align: "start",
        value: "device_code",
      },
      {
        text: "Stato programmazione",
        align: "center",
        value: "active_scheduler",
      },
      {
        text: "Nome cliente",
        align: "start",
        value: "client_name",
      },
      {
        text: "Indirizzo cliente",
        align: "start",
        value: "client_address",
      },
      {
        text: "Ultima volta online",
        align: "start",
        value: "last_online",
      },
      {
        text: "Data ultimo stato",
        align: "start",
        value: "last_state",
      },
    ],
  }),
  mounted: function () {
    this.error = "";
    this.loading = true;
    getAllMachines()
      .then((response) => {
        this.machines = response.map((machine) => {
          let lastOnline: Date | null = null;
          let lastState: Date | null = null;

          if(typeof machine.iot.lastHeartbeatTime === "string" && typeof machine.iot.lastEventTime === "string" && typeof machine.iot.lastStateTime === "string"){
            lastOnline = new Date(Math.max(new Date(machine.iot.lastHeartbeatTime || 0).getTime(), new Date(machine.iot.lastEventTime || 0).getTime()));
            lastOnline = lastOnline.getTime() === 0 ? null : lastOnline;
            lastState = new Date(machine.iot.lastStateTime || 0);
            lastState = lastState.getTime() === 0 ? null : lastState;
          } else {
            const lastHeartbeatSeconds = machine.iot.lastHeartbeatTime?.seconds;
            const lastEventSeconds = machine.iot.lastEventTime?.seconds;
  
            const lastStateSeconds = machine.iot.lastStateTime?.seconds;
  
            lastOnline = getDateFromIot(
              lastHeartbeatSeconds,
              lastEventSeconds
            );
  
            lastState = getDateFromIot(lastStateSeconds);
          }

          return {
            _id: machine.machine.id,
            device_code: machine.machine.device_code || "Non disponibile",
            active_scheduler: machine.machine.activeScheduler,
            client_name: machine.machine.client_name || "Non disponibile",
            client_address: machine.machine.client_address || "Non disponibile",
            last_online: lastOnline,
            last_state: lastState,
          };
        });
      })
      .catch((error) => {
        this.error = error;
      })
      .finally(() => {
        this.loading = false;
      });
  },
});
