import {
  IEditMeta,
  IEditParams,
  IViewDetailsMachines,
  IViewListMachines,
  IViewListUser,
} from "@iot-uta-devices/iot-uta-devices-interfaces";
import axios, { AxiosError, AxiosResponse } from "axios";
import { errorsHandler } from "@/api/utils/ErrorsHandler";
import store from "@/store";

const BASE_URL = process.env.VUE_APP_SERVER_BASE_URL;

export const getMachine = (machineID: string): Promise<IViewDetailsMachines> =>
  new Promise((resolve, reject) => {
    axios
      .get(`${BASE_URL}/machines/${machineID}`)
      .then(
        (response: AxiosResponse<{ readonly data: IViewDetailsMachines }>) => {
          resolve(response.data.data);
        }
      )
      .catch((error: AxiosError) => {
        let errorMessage;
        if (error.response) {
          errorMessage = error.response.data?.message || "Errore sconosciuto";
          errorsHandler(error);
          reject(new Error(errorMessage));
        } else {
          reject(error);
        }
      });
  });

export const getMachines = (
  machineIDs: Array<string>
): Promise<IViewListMachines> =>
  new Promise((resolve, reject) => {
    axios
      .all(
        machineIDs.map((machineID) =>
          axios
            .get(`${BASE_URL}/machines/${machineID}`)
            .catch((error: AxiosError) => {
              errorsHandler(error);
            })
        )
      )
      .then((response) => {
        const data = (
          response as unknown as Array<
            AxiosResponse<{ readonly data: IViewListMachines }>
          >
        )
          .filter((res) => res !== undefined)
          .map((res) => res.data.data);
        resolve(data as unknown as IViewListMachines);
      })
      .catch((error: AxiosError) => {
        let errorMessage;
        if (error.response) {
          errorMessage = error.response.data?.message || "Errore sconosciuto";
          errorsHandler(error);
          reject(new Error(errorMessage));
        } else {
          reject(error);
        }
      });
  });

export const getAllMachines = (): Promise<IViewListMachines> =>
  new Promise((resolve, reject) => {
    axios
      .get(`${BASE_URL}/machines`)
      .then((response: AxiosResponse<{ readonly data: IViewListMachines }>) => {
        resolve(response.data.data);
      })
      .catch((error: AxiosError) => {
        let errorMessage;
        if (error.response) {
          errorMessage = error.response.data?.message || "Errore sconosciuto";
          errorsHandler(error);
          reject(new Error(errorMessage));
        } else {
          reject(error);
        }
      });
  });

export const getAllMachinesExceptTheUserOnes = (
  machineIDs: Array<string>
): Promise<IViewListMachines> =>
  new Promise((resolve, reject) => {
    axios
      .get(`${BASE_URL}/machines`)
      .then((response: AxiosResponse<{ readonly data: IViewListMachines }>) => {
        resolve(
          response.data.data.filter(
            (machine) => !machineIDs.includes(machine.machine.id)
          )
        );
      })
      .catch((error: AxiosError) => {
        let errorMessage;
        if (error.response) {
          errorMessage = error.response.data?.message || "Errore sconosciuto";
          errorsHandler(error);
          reject(new Error(errorMessage));
        } else {
          reject(error);
        }
      });
  });

type BasicResponse = { readonly data: string; readonly message: string };

export const deleteMachine = (machineID: string): Promise<void> =>
  new Promise((resolve, reject) => {
    axios
      .delete(`${BASE_URL}/machines/${machineID}`)
      .then((response: AxiosResponse<BasicResponse>) => {
        resolve();
        store.commit("mutation_open_snackbar_success", response.data.message);
      })
      .catch((error: AxiosError) => {
        let errorMessage;
        if (error.response) {
          errorMessage = error.response.data?.message || "Errore sconosciuto";
          errorsHandler(error);
          store.commit("mutation_open_snackbar_error", errorMessage);
        } else {
          store.commit("mutation_open_snackbar_error", error);
        }
        reject();
      });
  });

export const putMachineMetaData = (
  machineID: string,
  payload: IEditMeta
): Promise<void> =>
  new Promise((resolve) => {
    axios
      .put(`${BASE_URL}/machines/${machineID}/meta`, { data: payload })
      .then((response: AxiosResponse<BasicResponse>) => {
        resolve();
        store.commit("mutation_open_snackbar_success", response.data.message);
      })
      .catch((error: AxiosError) => {
        let errorMessage;
        if (error.response) {
          errorMessage = error.response.data?.message || "Errore sconosciuto";
          errorsHandler(error);
          store.commit("mutation_open_snackbar_error", errorMessage);
        } else {
          store.commit("mutation_open_snackbar_error", error);
        }
        resolve();
      });
  });

export const putMachineParams = (
  machineID: string,
  payload: IEditParams
): Promise<void> =>
  new Promise((resolve) => {
    axios
      .put(`${BASE_URL}/machines/${machineID}/params`, { data: payload })
      .then((response: AxiosResponse<BasicResponse>) => {
        resolve();
        store.commit("mutation_open_snackbar_success", response.data.message);
      })
      .catch((error: AxiosError) => {
        let errorMessage;
        if (error.response) {
          errorMessage = error.response.data?.message || "Errore sconosciuto";
          errorsHandler(error);
          store.commit("mutation_open_snackbar_error", errorMessage);
        } else {
          store.commit("mutation_open_snackbar_error", error);
        }
        resolve();
      });
  });

  export const getMachineUsers = (machineID: string): Promise<IViewListUser> =>
  new Promise((resolve, reject) => {
    axios
      .get(`${BASE_URL}/machines/${machineID}/users`)
      .then((response: AxiosResponse<{ readonly data: IViewListUser }>) => {
        resolve(response.data.data);
      })
      .catch((error: AxiosError) => {
        let errorMessage;
        if (error.response) {
          errorMessage = error.response.data?.message || "Errore sconosciuto";
          errorsHandler(error);
          reject(new Error(errorMessage));
        } else {
          reject(error);
        }
      });
  });